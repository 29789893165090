import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseHttpService } from '@core/services/http/base-http.service';
import { Page } from '@core/data/model/page';
import { ActionItemStatusType } from '@core/data/type/action-item-status.type';
import { ActionItemPriorityType } from '@core/data/type/action-item-priority.type';
import { RetrospectiveActionItem } from '@core/data/model/retrospective-action-item';
import { RetrospectiveActionItemFilter } from '@core/data/model/dto/retrospective-action-item-filter';
import { RetrospectiveActionItemDto } from '@core/data/model/dto/retrospective-action-item.dto';

@Injectable()
export class RetrospectiveActionItemService extends BaseHttpService {

  constructor(protected override httpClient: HttpClient) {
    super('task-service/retrospective-action-items', httpClient);
  }

  searchActionItems(
    searchPhrase?: string,
    statuses?: ActionItemStatusType[],
    priorities?: ActionItemPriorityType[],
    page = 0,
    size = 30,
    sortKey = 'createdDate',
    sortDir = 'asc'
  ): Observable<Page<RetrospectiveActionItem>> {
    const filter: RetrospectiveActionItemFilter = {
      statuses: statuses,
      priorities: priorities,
      searchPhrase: searchPhrase
    };
    return this.post<Page<RetrospectiveActionItem>>(`/search?page=${page}&size=${size}&sort=${sortKey},${sortDir}`, filter);
  }

  createActionItem(actionItem: RetrospectiveActionItemDto): Observable<RetrospectiveActionItem> {
    return this.post<RetrospectiveActionItem>('', actionItem);
  }

  updateActionItem(id: string, actionItem: RetrospectiveActionItemDto): Observable<RetrospectiveActionItem> {
    return this.put<RetrospectiveActionItem>(`/${id}`, actionItem);
  }
}
